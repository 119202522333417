<template>
  <div>
    <LandingPageHeader />
    <div class="main-content">
     <h3 class="text-header-black">Privacy and cookie policy</h3>
      <p>Last updated: 07/01/2022</p>

      <h5>Who are we?</h5>
      
      <P>MatrimonyAssist (hereinafter "matrimonyassist.com" or "us" or "we" as the context requires) is operated by HMS Global Business Limited, a private limited company, registered in the UK (Reg No 07212488). We are a leading online service company. </P>
    <h5>This Policy</h5>
    <p>This Privacy and Cookie Policy governs your access to and use of any matrimonyassist.com website, applications, widgets, tools or other materials operated or otherwise provided by matrimonyassist.com (the " matrimonyassist.com Services"). The matrimonyassist.com  is committed to protecting and respecting your privacy and we make it our highest priority to safeguard the confidentiality of any and all personal details you provide to us. As a visitor to or user of the matrimonyassist.com services you have the right to know and understand our information privacy practices prior to providing us with any of your personal details.</p>
    <p>This document explains our Privacy and Cookie Policy. This policy (together with our Terms and Condition of Services and any other applicable terms and conditions or documents referred to therein) sets out the basis on which any personal data we collect from you, or that you provide to us, will be processed by us.</p>
    <p>Please read the following carefully to understand our views and practices regarding your personal data and how we will treat it.</p>
    <h5>Consent</h5>
    <p>By using the matrimonyassist.com services, you consent to (i) the collection and use of your IP Details (as defined below), Personal Information (as defined below) and location data; and (ii) the use of Cookies, in accordance with this policy. If we change our privacy and cookie policy at any time, these changes will be reflected in this policy.</p>
    <h5>Cookies</h5>
    <P>Just like other websites, our sites use cookies and similar technologies such as web beacons, pixel tags, google tag manager and flash objects. These allow our websites to work correctly, and helps us understand web traffic and how our sites are used so we can improve visitors experience, personalise services and tailor ads.</P>
    <h5>What is a cookie?</h5>
    <p>A cookie is a small text file that may be placed on your device when you visit our sites. When you next visit our websites, the cookie allows us to distinguish your device from other users’ devices.</p>
    <h5>Why do we use cookies?</h5>
    <p>
        <ul style="list-style-type: disc">
          <li>To allow our websites to work and help keep them safe and secure e.g. to load images and to allow you to log in securely.</li>
          <li>To improve the performance of our websites by helping us analyse how they are used and understand which parts work well, and which don't.</li>
          <li>To improve visitors experience on our websites, for example we use cookies to remember the products you’ve put in your basket, make personalised recommendations and to personalise your experience.</li>
          <li>To deliver relevant online advertising to visitors on our websites and third party websites. These cookies are placed by us and selected third parties and enable advertisements to be presented on our and third party websites.</li>
          <li>To understand how effective our online advertising communications are.</li>
        </ul>
        </p>
            <h5>What cookies do we use?</h5>
      <p>Depending on who owns the cookie, they are classified as:</p>

      <p>
      <ol style="list-style-type: 1">
        <li><strong>First-party cookies:</strong> These are created by the website (domain) you are visiting.</li>
        <li><strong>Third-party cookies:</strong> These are created by domains other than the one you are visiting. They allow tracking across different sites to help deliver services, and also to retarget and show ads to you.</li>
        </ol>
        </p>
        <p>
 <ol style="list-style-type: 1">
      <p>Depending on the lifespan of the cookie, they are classified as:</p>
      <li><strong>Persistent cookies:</strong> Persistent cookies remain on your device over a longer period of time defined by the owner of the cookie and are deleted manually or automatically afterwards.</li>
      <li><strong>Session cookies: </strong> Session cookies remain on your device only until you close your browser, then they are automatically deleted.</li>
      </ol>
</p>
      <p>Depending on the purpose of the cookie, they are classified as:</p>
     
      <p> <ol style="list-style-type: 1"> 

 <li><strong>Essential cookies: </strong> Essential cookies are technical cookies that are required for the operation of our websites. Without these our sites can’t operate properly. Examples include, cookies that enable you to log into secure areas, allow images to load, and orders to be processed.</li>
      <li><strong>Experience cookies:</strong> Experience cookies allow our websites to:</li>
      <ul style="list-style-type: disc"> <li>Recognise and count the number of visitors, and to see how they move through the website. This helps us to better understand and improve the way our sites work.</li>
      <li>Remember the choices you make, providing you with better and more personalised features. For example, to compare the choices you make to those of our other visitors so that we can learn from those choices and provide enhanced, more personalised features to you, such as product recommendations</li>
      </ul>
     </ol></p>

<p>Experience cookies cannot track your browsing activity when you leave our websites to browse other websites.</p>

 <p> <ol style="list-style-type: 1"> 

 <li><strong>Advertising cookies:  </strong> Advertising cookies record your visits to our websites, the pages you have visited and the links you have followed. We use this information to:</li>
     
      <ul style="list-style-type: disc"> <li>Help make the advertising displayed on our websites and certain third-party websites more relevant to your interests.</li>
      <li>Limit the number of times that you see an ad.</li>
      <li>Help measure the effectiveness of an advertising campaign.</li>
      </ul>
     </ol></p>
     <p>If you would like to find out more about the specific cookies we use, please contact us through help page.</p>

     <h5>Your right to refuse cookies and what happens if you refuse them</h5>
<p>When visiting the MatrimonyAssist website [https://matrimonyassist.com/] for the first time on a device, you will see a cookies banner explaining that we use cookies, why we use them and providing a link to this document.</p>
    <p>This banner gives you a settings option for our websites. If you click on this, you can turn certain cookies on and off.</p>
    <p>You can access these settings and change your choices at any time in the future by clicking on the Cookies Settings link at the bottom of our website pages.</p>
    <p>You can also control cookies by activating relevant settings on your browser. Please see below for more information on browser controls.</p>
    
    <p>Please be aware that if you turn cookies off you may not be able to access all or parts of our sites.</p>
    <h5>Browser controls</h5>

<p>You can use your web browser to:</p>

<p><ul style="list-style-type: disc"> 
  <li>delete all cookies;</li>
  <li>block all cookies;</li>
  <li>allow all cookies;</li>
  <li>block ‘third-party’ cookies;</li>
  <li>clear all cookies when you close the browser;</li>
  <li>open a ‘private browsing’ / ‘incognito’ session, which allows you to browse the web without recording your browsing history or storing local data such as cookies (you should however be aware of the limitations of this feature in a privacy context); and</li>
<li>install add-ons and plugins that extend browser functionality.</li>
</ul></p>

<p>Your choice to refuse cookies is specific to both the browser and the device that you are using. So, if you make a choice to opt out from one device and you want your opt-out to apply to a different device as well, you will have to opt out from that device too.</p>
<p>You can use the links below to find out how to use your browser to control cookies:</p>

<p><ul style="list-style-type: disc"> 
            <li> <a href="https://support.microsoft.com/en-us/help/4468242/microsoft-edge-browsing-data-and-privacy" target="_blank">Microsoft Edge Cookies Information </a></li>
            <li> <a href="https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies" target="_blank">Internet Explorer Cookies Information</a></li>
            <li> <a href="https://support.google.com/chrome/answer/95647?hl=en-GB" target="_blank">Chrome Cookies Information</a></li>
            <li> <a href="https://support.mozilla.org/en-US/kb/delete-browsing-search-download-history-firefox " target="_blank">Firefox Cookies Information </a></li>
            <li> <a href="https://support.apple.com/en-gb/HT201265" target="_blank">Safari Cookies Information – Mobile devices and Desktops</a></li>
            <li> <a href="https://help.opera.com/en/latest/web-preferences/" target="_blank">Opera Cookies Information</a></li>
          </ul></p>

          <h5>Other controls</h5>
<p>In relation to third party advertising cookies, third parties can participate in one of the following self-regulatory programs for online behavioural advertising, which gives users opt-outs:</p>

<p><ul style="list-style-type: disc"> 
            <li><a href="https://optout.networkadvertising.org/?c=1" target="_blank">Network Advertising Initiative (NAI)</a></li>
            <li><a href="https://tools.google.com/dlpage/gaoptout" target="_blank">Google Analytics</a></li>
          </ul></p>

          <p>If you don’t want your personal information to be used to allow third parties (e.g. Google, Facebook) to display ads or to personalise ads displayed to you, you can change your settings by using the tools provided by their sites or through one of the above self-regulatory programmes, if they participate in them.</p>
<p>
          You can also find more detailed about how you can manage cookies at the <a href="https://www.allaboutcookies.org/" target="_blank"> All About Cookies</a> and
          <a href="https://www.youronlinechoices.com/uk/" target="_blank">Your Online Choices</a> websites.
        </p>

        <h5>Information Collection and Use</h5>
        <p>We may collect information from you at several stages during your use of the matrimonyassist.com services. We may collect and process the following information about you:</p>
   
   <h5>IP Details</h5>
   <p>Where you are accessing the matrimonyassist.com services via a computer, we may collect information about that computer, including where available your IP address, operating system, browser type, referring URLs, page views and access times ("IP Details"). We use such information for system administration, to analyse trends, track users' movements and to report aggregate information to our advertisers and partners. This statistical data about our users' browsing actions and patterns do not identify any individual.</p>
  
   <h5>Cookies and Advertisements</h5>
   <p>For the same reason, we may obtain information about your general internet usage by using a "cookie" file which is stored on the hard drive of your computer. A cookie consists of information sent by a web server to a web browser and stored by the browser. The information is then sent back to the server each time the browser requests a page from the server. This enables the web server to identify and track the web browser.</p>
   <p>We may use both "session" cookies and "persistent" cookies on our websites. We will use the session cookies to keep track of you whilst you navigate the websites. We will use the persistent cookies to enable our websites to recognise you when you visit.</p>
   <p>We use cookies to make your use of our websites easier. Usage of a cookie is in no way linked to any personally identifiable information while on our website. For instance, by allowing a cookie from our websites, you would not have to set your currency or language preferences each time you used our website, thereby saving time while on our website. As such, cookies allow you to get the most out of our websites. In addition, as the cookie is stored on your computer, the data gathered by the cookie remains under your control. Please note that disabling cookies may prevent you from accessing some features of these and other websites. We therefore recommend you permit the use of cookies when using our websites.</p>
   <p>Session cookies will be deleted from your computer when you close your browser. Persistent cookies will remain stored on your computer until deleted, or until they reach a specified expiry date.</p>
   <p>Most browsers allow you to reject all cookies, whilst some browsers allow you to reject just third-party cookies. For example, in Internet Explorer you can refuse all cookies by clicking "Tools", "Internet Options", "Privacy", and selecting "Block all cookies" using the sliding selector. Blocking all cookies will, however, have a negative impact upon the usability of many websites, including our websites.</p>
    
    <p>We also use Google Analytics to analyse the use of our websites. Google Analytics generates statistical and other information about website use by means of cookies, which are stored on users' computers. The information generated is anonymous and is used to create reports about the use of the website. Google will store this information. Google's privacy policy is available at:<a href="https://policies.google.com/privacy?hl=en-GB&gl=uk" target="_blank"></a>.
    </p>

    <p>We may publish Google AdSense interest-based advertisements on our websites. These are tailored by Google to reflect your interests. To determine your interests, Google will track your behaviour across the web using cookies. You can view, delete or add interest categories associated with your browser using Google's Ads Preference Manager, available at: <a href=" https://www.google.com/ads/preferences/" target="_blank"></a>. You can opt-out of the AdSense partner network cookie at: <a href="https://policies.google.com/technologies/ads" target="_blank"></a>. However, this opt-out mechanism uses a cookie, and if you clear the cookies from your browser, your opt-out will not be maintained. To ensure that an opt-out is maintained in respect of a particular browser, you should use the Google browser plug-in available at: <a href="https://www.google.com/ads/preferences/plugin/" target="_blank"></a></p>
    <p>Third-party companies may collect and use information about your visits to our, and other third parties' websites in order to provide advertisements about goods and services of interest to you. If you would like more information about this practice and to know your choices about not having this information used by these companies, please visit <a href="http://optout.networkadvertising.org/?c=1#!/" target="_blank"></a>.</p>
   
   <h5>Personal Information</h5>
   <p>We may collect and process the following data ("Personal Information") about you:</p>
<p>
  <ul style="list-style-type: disc"> 

    <li>where you choose to register to use our websites or certain other services of matrimonyassist.com, either through a web browser or mobile application (our "Join Us” service), we will collect certain personal information provided by you which may include your name, phone number and email address including your personal preferences (for full list please see our Join Us form). Additional information may be requested in future to improve the level of service we can provide you, and to allow access to additional matrimonyassist.com services. </li>
 <li>

   any information that you provide by filling in forms on or through our websites or any other of the matrimonyassist.com Services; this includes information provided by you if subscribing to our newsletter, posting material, searching for or saving package options or requesting further services. We may also ask you for information when you enter a promotion sponsored by us and when you report a problem with our website our other matrimonyassist.com services.
 </li>

 <li>
  if you seek support from us or contact us for any reason, we may keep a record of that correspondence. Where you contact us through the 'Support' section of our websites, your query will be routed to our customer service team through a third party service desk.
 </li>

 <li>information you provide to us when you complete surveys that we use for research purposes.</li>
 <li>information that you provide to us as a result of your connecting to our websites or other matrimonyassist.com services from a third-party application, social networking or other site.</li>
 
  </ul>
</p>
  <h5>Location Data</h5>
<p>We may obtain information relating to your geographical location ("Location Data") where you use any matrimonyassist.com services. We will use such Location Data only to understand the area from which our most traffic comes and will not retain this information or share it with third parties.</p>
  
  <h5>

    Use of Information
  </h5>
  <p>We will use Cookies and the IP Details and Personal Information that we hold about you in the following ways:</p>
  <p>

  <ul style="list-style-type: disc"> 

<li>
to ensure that content from our websites and other matrimonyassist.com services is presented in the most effective manner for you and for your computer, mobile phone or other electronic device.

</li>

  <li>
    to provide you with information, products or services that you request from us or which we feel may interest you.
  </li>
<li>to carry out our obligations arising from any contracts entered into between you and us.</li>

<li>
  to allow you to participate in interactive features of our service, when you choose to do so.
</li>

<li>to notify you about changes to our service.</li>
<li>to respond to client support queries where you have supplied personal information.</li>

  </ul>
  </p>
  <p>We will not sell, share, or provide your IP Details, Cookies, Personal Information and Location Data (together, your "Information") to others other than in accordance with this Privacy Policy or where we are required to do so by law.</p>
    <h5>
      Disclosure of Your Information
    </h5>
    

    <p>We may disclose your Information to any member of our group, which means our subsidiaries, our ultimate holding company and its subsidiaries, as defined in section 1159 of the UK Companies Act 2006.</p>
    <p>In addition, we may disclose your Information to third parties:</p>

    <p>
      <ul style="list-style-type: disc"> 

      <li>
if we are under a duty to disclose or share your personal data in order to comply with any legal obligation, or in order to enforce or apply our Terms of Service and other agreements; or to protect the rights, property, or safety of matrimonyassist.com, our clients, or others. This includes exchanging information with other companies and organisations for the purposes of fraud protection and credit risk reduction.
      </li>


    </ul>
    </p>
<p>Sometimes we use third parties to process your information on our behalf. We require these third parties (some of which may be based outside of the EEA) to comply strictly with our instructions and we require that they do not use your Information for their own business purposes, unless you have explicitly consented to the use of your Information in this way. Any third parties who access your data in the course of providing services on our behalf are subject to strict contractual restrictions to ensure that your data is protected, and in compliance with applicable data protection/privacy legislation. We may also independently audit these service providers to ensure that they meet our standards.</p>
<h5>Data Storage</h5>

<p>All Information you provide to us is stored on our secure servers. Unfortunately, the transmission of information via the internet is not completely secure. Although we will do our best to protect your Information, we cannot guarantee the security of your data transmitted to our websites or other matrimonyassist.com services; any transmission is at your own risk. Once we have received your Information, we will use strict procedures and security features to try to prevent unauthorised access.</p>
    <p>We use security measures, including physical, administrative, and technical safeguards to protect the confidentiality of your Personal Information. These measures include encryption, security certificates, access controls, information security technologies, policies, procedures and other information security measures to help protect your Information.</p>
    <p>When designing or implementing new computer systems and processes we look at ways to identify and mitigate potential security risks and then monitor and test our security systems to help protect your Personal Information.</p>
<p>Where possible, we also try to anonymise information so that individuals can’t be identified from it. We analyse data about our users’ service uses patterns and habits, to help us improve our services, how we display them on our website and so on. However, we don’t need to know who these service users are, we just need to know information such as how many connections made a certain teams at certain times, or how many subscriptions bought over the course of times. So, instead of just putting all of our users’ information together and analysing it, we first remove the pieces of information that could identify them, such as names, contact details, addresses and so on.</p>
<p>Unless otherwise stated in one of the specific Privacy Notices, we will keep your Personal Information for three years then we will securely delete it.</p>
   <p>We do need to keep some anonymous information for longer than this, such as subscriptions habits and patterns, so we can analyse it to identify trends and changes in activity. We remove all names, contact details and any other information that identify individual customers, so it’s all just anonymous numbers and data.</p> 
    
    <h5>Links to third party websites</h5>
<p>The matrimonyassist.com services may contain links to third party websites. In particular, matrimonyassist.com services may direct you to the websites of third-party companies to search and place order. In order to place order, you are not required provide personal data to the operators of such websites. When contacting us through the contact us form or FAQ content on our website, you will provide personal data that is handled by a customer support third party website. Please be aware that we are not responsible for the privacy practices of such other sites. When you leave or otherwise cease using the matrimonyassist.com services, we encourage you to read the privacy statements/policies of each and every third-party website that collects personal data from you. This Privacy Policy applies solely to information collected by us via the matrimonyassist.com services.</p>
<h5>Sharing Information</h5>
<p>In order to provide you with a useful social experience when using matrimonyassist.com services, we may give you the option to export/import information from/to such matrimonyassist.com services to/from third party applications and websites, including social networking sites such as Facebook, Instagram or Twitter. Please be aware when exporting such information, you may be disclosing your information to the individuals or organisations responsible for operating and maintaining such third party applications and sites and that your information may be accessible by others visiting or using those applications or sites. We do not own or operate the applications or websites that you connect with and you should review the privacy policies of such websites to make sure you are comfortable with the ways in which they use the information you share with them.</p>
    
    <p>When you connect to our websites or other matrimonyassist.com services from a third-party application, social networking or other site then you consent to the collection by us of your information as stored with that other third party. This information may be used by us to enhance your use of our website or other matrimonyassist.com services. When we do obtain information about you from these third parties, we will process the information in accordance with our own privacy policy.</p>
    <h5>Your Rights</h5>
    <p>You have the right to access personal data which you have submitted through the matrimonyassist.com services which are stored by us. We also allow you to subscribe or unsubscribe to / from any of the email updates you have requested form the website or other matrimonyassist.com services.</p>
    <p>You have the right to ask us not to process your personal data for marketing purposes. You can exercise your right to prevent such processing by checking certain boxes on the forms we use to collect your data. You can also exercise the right at any time by contacting us by clicking here.</p>
    <h5>Email Addresses</h5>
    <p>We do not want to send you unwanted email. You can opt-out from our mailings at any time by clicking the unsubscribe link contained in every mail we send.</p>
    <p>Email addresses are collected via our websites (including via our Facebook and other social media pages), apps and via co-registration partners.</p>
    <p>We use email addresses to send our weekly newsletter, and occasional special offers and deals. We sometimes run promotions with partners and competition entries may require you to provide an email address. These email addresses may be shared with the partner running the competition. In such cases, this is clearly stated in the terms of the promotion. We have no responsibility for subsequent use or distribution of email addresses by our partners.</p>
   <p>If you have any questions about our use of cookies, please contact us via the help page.  </p>
    </div>
    <Footer />
  </div>
</template>

<script>
import Footer from "@/components/auth/Footer.vue";
import LandingPageHeader from "@/components/landing-page/LandingPageHeader.vue";
export default {
  name: "PrivacyPolicy",
  components: {
    LandingPageHeader,
    Footer,
  },
};
</script>

<style scoped lang="scss">
@import "@/styles/base/_variables.scss";

.main-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  overflow: hidden;
  max-width: 1100px;
  margin:10px auto;
  padding: 0 10px;
  @media (min-width: 320px) and (max-width: 480px) {
    padding: 0 20px;
  }
 
}

</style>